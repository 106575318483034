<template>
  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-center"/>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <DataView :layout="layout" :paginator="false" :rows="9" :sortField="sortField" :sortOrder="sortOrder"
                  :value="store.virtualNumberConfigList">
          <template #header>
            <div class="grid grid-nogutter">
              <div class="col-6 text-left">
              </div>
              <div class="col-6 text-right">
                <Button class="mb-2 p-button-danger" icon="pi pi-calendar-plus" label="Configure New DialX Number"
                        @click="store.open()"></Button>
              </div>
            </div>
            <div v-if="store.listLoading" class="col-12">
              <ProgressBar mode="indeterminate" style="height: .5em"></ProgressBar>
            </div>
          </template>
          <template #grid="slotProps">
            <div class="col-12 md:col-4">
              <div class="card m-3 border-1 surface-border">
                <div class="flex align-items-center justify-content-between">
                  <div class="flex align-items-center">
                    <i class="pi pi-tag mr-2"></i>
                    <span class="font-semibold">{{ slotProps.data.primaryNumber }}</span>
                  </div>
                  <span v-if="slotProps.data.status === 'PENDING'"
                        :class="'product-badge status-pending'">PENDING</span>
                  <span v-if="slotProps.data.status === 'ACTIVE'"
                        :class="'product-badge status-active'">ACTIVE</span>
                  <span v-if="slotProps.data.status === 'EXPIRED_CONFIG_KEEP_NUMBER_LOCKED'"
                        :class="'product-badge status-expired'">EXPIRED</span>
                  <span v-if="slotProps.data.status === 'KEEP_NUMBER_ON_HOLD'"
                        :class="'product-badge status-expired'">EXPIRED</span>
                  <span v-if="slotProps.data.status === 'RELEASE_NUMBER_TO_PUBLIC_POOL'"
                        :class="'product-badge status-expired'">RELEASE TO PUBLIC</span>
                  <span v-if="slotProps.data.status === 'NUMBER_REASSIGNED'"
                        :class="'product-badge status-expired'">NUMBER REASSIGNED</span>                        
                  <span v-if="slotProps.data.status === 'DEACTIVATED_CONFIG_KEEP_NUMBER_LOCKED'"
                        :class="'product-badge status-expired'">PENDING DEACTIVATION</span>
                </div>
                <br/>
                <div class="text-center">

                  <div class="text-2xl font-bold">{{ slotProps.data.virtualNumber }}</div>
                  <div class="mb-3">Call back number 8899</div>
                </div>
                <br/>
                <div class="flex align-items-center justify-content-between">
                  <span class="text-s font-semibold">From: {{
                      slotProps.data.fromFormatted
                    }}</span>
                </div>
                <div class="flex align-items-center justify-content-between">
                  <span v-if="slotProps.data.noOfDays=='_7_DAYS'" class="text-s font-semibold">Upto: 7 Days</span>
                  <span v-if="slotProps.data.noOfDays=='_14_DAYS'" class="text-s font-semibold">Upto: 14 Days</span>
                  <span v-if="slotProps.data.noOfDays=='_30_DAYS'" class="text-s font-semibold">Upto: 30 Days</span>
                </div>
                <div class="flex align-items-center justify-content-between">
                  <span class="text-s font-semibold"></span>
                  <!--                  <Button v-if="slotProps.data.status === 'PENDING'" icon="pi pi-pencil"-->
                  <!--                          @click="store.edit(slotProps.data)" desabled="true"></Button>-->
                  <!--                  <Button v-if="slotProps.data.status === 'ACTIVE'" desabled="true" icon="pi pi-stop"></Button>-->
                  <Button v-if="slotProps.data.status === 'EXPIRED_CONFIG_KEEP_NUMBER_LOCKED'"
                          @click="store.edit(slotProps.data)">Renew
                  </Button>
                  <Button v-if="slotProps.data.status === 'ACTIVE'" :loading="store.deactivating" icon=""
                          class="p-button-danger" @click="confirmDeactivate($event,slotProps.data)">Deactivate
                  </Button>
                </div>
              </div>
            </div>
          </template>
          <template #empty>No records found.</template>
        </DataView>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="store.displayForm" :breakpoints="{'960px': '75vw'}" :modal="true" :style="{width: '50vw'}"
          header="Configure DialX Number">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="primary-number">Primary Number<span style="color:red;">*</span></label>
            <Dropdown id="primary-number" v-model="store.primaryNumber" v-on:change="store.fetchVirtualNumbersOne()"
                      :class="{'p-invalid': store.isInvalidPrimaryNumber}" :options="store.primaryNumberList"
                      optionLabel="name" optionValue="code" placeholder="Select Primary Number"></Dropdown>
          </div>
          <div class="field col-12 md:col-6">
            <label for="virtual-number">DialX Number<span style="color:red;">*</span></label>
            <!--            <Dropdown id="virtual-number" v-model="store.virtualNumber"-->
            <!--                      :class="{'p-invalid': store.isInvalidVirtualNumber}" :options="store.virtualNumbersList"-->
            <!--                      optionLabel="name" optionValue="code" placeholder="Select One"></Dropdown>-->
            <InputText id="virtual-number" v-model="store.virtualNumber" disabled type="text"/>
          </div>
          <div class="field col-12 md:col-6">
            <label for="from">From<span style="color:red;">*</span></label>
            <Calendar id="from" v-model="store.from" :class="{'p-invalid': store.isInvalidFrom}"
                      :minDate="store.minDate"
                      autocomplete="off"/>
          </div>
          <div class="field col-12 md:col-6">
            <label for="no-of-days">No. of days<span style="color:red;">*</span></label>
            <Dropdown id="no-of-days" v-model="store.noOfDays" :class="{'p-invalid': store.isInvalidNoOfDays}"
                      :options="dropdownItems2"
                      optionLabel="name" optionValue="code" placeholder="Select One"></Dropdown>
          </div>
          <div class="field col-12 md:col-1">
            <Checkbox id="first" v-model="store.terms" :binary="true" :class="{'p-invalid': store.isInvalidTerms}"/>
          </div>
          <div class="field col-12 md:col-11">
            <label for="first">I acknowledge that I have read, and do hereby accept the
              <a href="https://dialx.ideabiz.lk/terms-and-conditions.html" target="_blank">terms and conditions
                &nbsp;<i class="pi pi-external-link" style="font-size: 0.75rem"></i>&nbsp;</a>
              contained here while taking full responsibility for all the incoming and outgoing calls generated through
              the DialX number.
            </label>
          </div>
          <div class="field col-12 md:col-1">

          </div>
          <div class="field col-12 md:col-11">
            <div class="field col-12 md:col-1">

            </div>
            <div class="field col-12 md:col-11">
              <p class="text-xs w-10 text-pink-600">* All fields are required.</p>
              <p v-if="store.mainError.length>0" class="text-xl w-10 text-pink-600">{{ store.mainError }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="Cancel" @click="store.close()"/>
      <Button :loading="store.saving" class="p-button-primary" icon="pi pi-check" label="Save" @click="store.save()"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "../store/Store";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
import {ref} from 'vue';

export default {
  setup() {
    const confirm = useConfirm();
    const toast = useToast();
    const store = useStore()

    const displayTAC = ref(false);
    const openTAC = () => {
      displayTAC.value = true;
    };
    const closeTAC = () => {
      displayTAC.value = false;
    };

    store.toast = toast;

    store.fetchVirtualNumberConfigList()
    // store.fetchVirtualNumbersList()
    let display = false
    const onSortChange = (event) => {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    }

    const confirmDeactivate = (event, data) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to deactivate the DialX number?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          store.update_status(data.id, toast)
        },
        reject: () => {
          console.info("deactivation reject")
        }
      });
    }

    return {
      store,
      confirmDeactivate,
      open,
      close,
      onSortChange,
      display,
      dropdownItems2: [
        {name: '7 days', code: '_7_DAYS'},
        {name: '14 days', code: '_14_DAYS'},
        {name: '30 days', code: '_30_DAYS'},

      ],
      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        {label: 'Newest first', value: '!price'},
        {label: 'Oldest', value: 'price'},
      ],
      displayTAC, openTAC, closeTAC
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/demo/badges.scss';
</style>
